import "./Navabar.css";
import logo from "../../assets/images/yuave-white-logo.png";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import send from "../../assets/images/send.svg";
import email from "../../assets/images/email.svg";
import call from "../../assets/images/call.svg";
import headphones from "../../assets/images/headphones.svg";
import aiheadphones from "../../assets/images/chat-bot.png";
import connect from "../../assets/images/connectivity.svg";
import speaker from "../../assets/images/speaker.svg";
import record from "../../assets/images/voice-recorder.svg";
import ContactForm from "../ContactForm/ContactForm";

import fintech_icon from "../../assets/images/fintech-icon.svg";
import government_icon from "../../assets/images/government-icon.svg";
import insurace_icon from "../../assets/images/insurance-icon.svg";
import number_icon from "../../assets/images/number-icon.svg";
import retial_icon from "../../assets/images/retail-icon.svg";
import sharing_icon from "../../assets/images/Sharing-icon.svg";
import travel_icon from "../../assets/images/travel-icon.svg";
import user_verification_icon from "../../assets/images/user-verification-icon.svg";
import customer_stories_icon from "../../assets/images/customer_stories_icon.svg";
import help_center_icon from "../../assets/images/help_center_icon.svg";

import resourcesForDevs from "../../assets/images/Resources-For-Developers.svg";
import resDataProtection from "../../assets/images/Resources-DATA_PROTECTION.svg";
import resDesktoApp from "../../assets/images/Resources-Desktop-App.svg";
import resFaq from "../../assets/images/Resources-FAQs.svg";
import integration from "../../assets/images/Integrate Voice, Whatsapp, Facebook _ Instagram.svg";


import edutech_icon from "../../assets/images/edutech.svg";
import debt_collector_icon from "../../assets/images/debt-collector.svg";
import call_center_icon from "../../assets/images/outbound call.svg";

import background_img from "../../assets/images/firstsection-background1.png";
const Navbar = (props) => {
  const [productIsShow, setProductIsShow] = useState(false);

  return (
    <div>
      <main role="main">
        <header class="header-hero position-fixed ">
          <div class="container">
            <div class="row align-items-center">
              <a href="/" class="col-auto header-logo">
                <img
                  width={"140px"}
                  loading="lazy"
                  src={logo}
                  alt="Group 6722"
                  class=""
                />
              </a>

              <div class="col d-none d-xxl-flex align-items-center">
                <nav class="navbar navbar-expand-xxl">
                  <div class="w-100">
                    <div
                      class="collapse navbar-collapse"
                      id="main_nav_magamenu"
                    >
                    <ul class="navbar-nav">
                       <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Solution for Companies <i class="bi bi-chevron-down">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg></i>
                          </a>
                          <ul class="dropdown-menu navbar-custom-dropdown">
                            <li><a class="dropdown-item disabled dropdown-heading" href="#" tabindex="-1" aria-disabled="true"><i class="mb-5"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe-europe-africa" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.81.81 0 0 1 1.034-.275.81.81 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34q.118.04.243.054c.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.33.33 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501"/>
</svg></i> Select your country</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://kasookoo.com/">Nigeria</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://kasookoo.com/">Ghana</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://kasookoo.com/">Kenya</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://standardpro.mtn.ci/">Cote d’ivoire</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://kasookoo.com/">South Africa</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://standardpro.mtn.bj/">Benin</a></li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="navbar-nav">
                        <li class="nav-item dropdown has-megamenu">
                          <a
                            class="nav-link  dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            Products
                          </a>
                          <div
                            class="dropdown-menu megamenu"
                            role="menu"
                            style={{ left: "250px" }}
                          >
                            <ul class="mega-col">
                              <div class="mega-col-title  big">
                                CUSTOMER ENGAGEMENT
                              </div>
                              <li>
                                <a
                                  onClick={window.scrollTo(0, 0)}
                                  class="dropdown-item d-flex"
                                  href="/#/cloud-numbering-service"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={connect}
                                      alt="Cloud numbering service"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Cloud Numbering Service
                                    </span>
                                    <span class="mega-item-text">
                                      Elevate your telecommunications experience
                                    </span>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/ai-solutions"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={aiheadphones}
                                      alt="AI Solutions"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      AI Solutions
                                    </span>
                                    <span class="mega-item-text">
                                      AI solutions focused on African languages
                                    </span>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/contact-center"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={headphones}
                                      alt="Contact Centre"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Contact Centre
                                    </span>
                                    <span class="mega-item-text">
                                      Create a personalized customer experience
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/campaign-manager"
                                >
                                  <span class="mega-item-icon">
                                    <img src={send} alt="Campaign Manager" />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Campaign Manager
                                    </span>
                                    <span class="mega-item-text">
                                      Craft, customize, and initiate large-scale
                                      messaging campaigns
                                    </span>
                                  </span>
                                </a>
                              </li>
                              <div class="mega-col-title  big">
                                BUSINESS TELEPHONY
                              </div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/kasookoo-for-crm"
                                >
                                  <span class="mega-item-icon">
                                    <img src={call} alt="Kasookoo for CRM " />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Yuave for CRM
                                    </span>
                                    <span class="mega-item-text">
                                      Embrace a deskphone-free environment
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/zoho-phonebridge-integration"
                                >
                                  <span class="mega-item-icon">
                                    <img src={integration} class="integration-logo" alt="Kasookoo for CRM " />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Integrations
                                    </span>
                                    <span class="mega-item-text">
                                    Incorporate Calling Seamlessly into Your Work Processes
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/kasookoo-for-zoom"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={connect}
                                      alt="Yuave for Zoom Phone"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Yuave for Zoom Phone
                                    </span>
                                    <span class="mega-item-text">
                                      All-in-one business communications
                                      platform
                                    </span>
                                  </span>
                                </a>
                              </li>
                              

                            </ul>
                            <ul class="mega-col">
                              <div class="mega-col-title  big">
                                EMBEDDED COMMUNICATION
                              </div>
                              {/* <div class="mega-col-title ">VOICE</div> */}

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/programmable-voice"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={record}
                                      alt="Programmable Voice"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Programmable Voice
                                    </span>
                                    <span class="mega-item-text">
                                      Integrate voice call functionality into
                                      your product
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/in-app-voice-calling"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={speaker}
                                      alt="In-App Voice Calling"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      In-App Voice Calling
                                    </span>
                                    <span class="mega-item-text">
                                      Empower your app with the capability of
                                      voice calling
                                    </span>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/programmable-messaging"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={email}
                                      alt="Programmable Messaging"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Programmable Messaging
                                    </span>
                                    <span class="mega-item-text">
                                      Experience the messaging journey you
                                      aspire
                                    </span>
                                  </span>
                                </a>
                              </li>
                              {/* <div class="mega-col-title ">MESSAGING</div> */}

                              
                            </ul>
                          </div>
                        </li>
                        <li class="nav-item dropdown has-megamenu">
                          <a
                            class="nav-link  dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            Solutions
                          </a>
                          <div class="dropdown-menu megamenu" role="menu">
                            <ul class="mega-col">
                              <div class="mega-col-title  big">BY INDUSTRY</div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/government-solution"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={government_icon}
                                      alt="Government & Political Campaign/Surveys"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Government & Political Campaign/Surveys
                                    </span>
                                    <span class="mega-item-text">
                                      Interact with a large audience of citizens
                                      and staff members
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item d-flex" href="/#/FinTech">
                                  <span class="mega-item-icon">
                                    <img src={fintech_icon} alt="Fintech" />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">Fintech</span>
                                    <span class="mega-item-text">
                                      Manage your finance, banking and
                                      investment with a smooth digital
                                      experience
                                    </span>
                                  </span>
                                </a>
                              </li>

                              {/* <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/sharing-economy"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={sharing_icon}
                                      alt="Sharing Economy "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Sharing Economy
                                    </span>
                                    <span class="mega-item-text">
                                      Boost your operational efficiency and
                                      communications
                                    </span>
                                  </span>
                                </a>
                              </li> */}

                              {/* <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/insurance-services"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={insurace_icon}
                                      alt="Insurance Services "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Insurance Services
                                    </span>
                                    <span class="mega-item-text">
                                      Create a better experience for your agents
                                      and customers
                                    </span>
                                  </span>
                                </a>
                              </li> */}

                              <li>
                                <a class="dropdown-item d-flex" href="/#/EduTech">
                                  <span class="mega-item-icon">
                                    <img src={edutech_icon} alt="Edutech" />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">Edutech</span>
                                    <span class="mega-item-text">
                                      Empowering minds through education
                                      technology
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                              <a class="dropdown-item d-flex" href="/#/outbound-callcentre">
                                  <span class="mega-item-icon">
                                    <img src={call_center_icon} alt="outbound"  style={{height:"30px"}}/>
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title"> Outbound Call Centre</span>
                                    <span class="mega-item-text">
                                    Connecting customers, delivering solutions
                                    </span>
                                  </span>
                                </a>
                                       </li>
                              <li>
                                <a class="dropdown-item d-flex" href="/#/debtcollection">
                                  <span class="mega-item-icon">
                                    <img
                                      src={debt_collector_icon}
                                      alt="Debt Collection"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Debt Collection
                                    </span>
                                    <span class="mega-item-text">
                                      Reclaiming Financial Freedom, One Step at
                                      a Time
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/hostpitality"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={travel_icon}
                                      alt="Travel &amp; Hospitality "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Travel &amp; Hospitality
                                    </span>
                                    <span class="mega-item-text">
                                      Build an omnichannel hospitality
                                      experience
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/retail-ecommerce"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={retial_icon}
                                      alt="Retail &amp; eCommerce "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      E-commerce &amp; FMCG
                                    </span>
                                    <span class="mega-item-text">
                                      Design great purchasing experiences with
                                      convenience
                                    </span>
                                  </span>
                                </a>
                              </li>
                            </ul>
                            <ul class="mega-col">
                              <div class="mega-col-title  big">BY USE CASE</div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/number-masking"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={number_icon}
                                      alt="Number Masking "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Number Masking
                                    </span>
                                    <span class="mega-item-text">
                                      Ensure user privacy through anonymization
                                      techniques
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/user-varification"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={user_verification_icon}
                                      alt="User Verification "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      User Verification
                                    </span>
                                    <span class="mega-item-text">
                                      Authenticate legitimate users across
                                      multiple channels
                                    </span>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/#/consutling">
                            Consulting
                          </a>
                        </li>
                        {/* <li class="nav-item">
                          <a class="nav-link" href="/#/why-yuave">
                            Why Yuave
                          </a>
                        </li> */}
                        <li class="nav-item dropdown has-megamenu">
                          <a
                            class="nav-link  dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            Resources
                          </a>
                          <div class="dropdown-menu megamenu" role="menu">
                            <ul class="mega-col">
                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/#/customer"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={customer_stories_icon}
                                      alt="Customer Stories"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Customer Stories
                                    </span>
                                    <span class="mega-item-text"></span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="https://kasookoo.com/downloads/kasookoo-app.zip"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={resDesktoApp}
                                      alt="Customer Stories"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Kasookoo Desktop App
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item d-flex" href="/#/faqs">
                                  <span class="mega-item-icon">
                                    <img src={resFaq} alt="FAQs" />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">FAQs</span>
                                    <span class="mega-item-text"></span>
                                  </span>
                                </a>
                              </li>

                              {/* <li>
                                <a
                                  class="dropdown-item d-flex"
                                  
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={resourcesForDevs}
                                      alt="forDevs"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                     For Developer
                                    </span>
                                    <span class="mega-item-text">
                                     
                                    </span>
                                  </span>
                                </a>
                              </li> */}
                              <li>
                                <a class="dropdown-item d-flex" href="/#/gdpr">
                                  <span class="mega-item-icon">
                                    <img
                                      src={resDataProtection}
                                      alt="DataProtection"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      EU Data Protections
                                    </span>
                                    <span class="mega-item-text"></span>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/#/about-us">
                            About Us
                          </a>
                        </li>
                        {/* <li class="nav-item dropdown has-megamenu">
	<a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Support</a>
	<div class="dropdown-menu megamenu" role="menu" >
		<ul class="mega-col">



<li>
	<a class="dropdown-item d-flex" href="help-center">
					<span class="mega-item-icon">
				<img src={help_center_icon} alt="Help Centre   "/>
			</span>
				<span class="mega-content">
            <span class="mega-item-title">Help Centre   </span>
							<span class="mega-item-text">Need help? Get support, read our FAQs, and find info on our products</span>
			        </span>
	</a>
</li>
</ul>	</div>
</li> */}

                        {/* <li class="nav-item dropdown has-megamenu">
                          <a class="nav-link  dropdown-toggle" href="/#/careers">
                            Careers
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>

              <div class="col-auto px-1 d-none d-sm-flex ms-auto me-0">
                <a
                  href="/#/contact-us"
                  class="btn btn-big btn-yellow border-radius-25 mb-0 "
                >
                  Contact Us
                </a>
                {/* <a
                  href="/login"
                  class="btn btn-big btn-yellow border-radius-25 mb-0 navbar-login-button"
                >
                  Login
                </a> */}
              </div>

              <nav class="col-auto navbar d-flex d-xxl-none align-items-center me-0 ms-auto ms-sm-2">
                <button
                  class="navbar-toggler"
                  type="button"
                  role="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar"
                  style={{ border: "none", outline: "none" }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span class="navbar-toggler-icon visually-hidden"></span>
                </button>
              </nav>
            </div>
          </div>
        </header>

        <div class="element-canvas">
          <div
            class="offcanvas d-xxl-none"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1 w-lg-50 mx-auto">
                       <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Solution for Companies <i class="bi bi-chevron-down">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg></i>
                          </a>
                          <ul class="dropdown-menu navbar-custom-dropdown">
                            <li><a class="dropdown-item disabled dropdown-heading" href="#" tabindex="-1" aria-disabled="true"><i class="mb-5"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe-europe-africa" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.81.81 0 0 1 1.034-.275.81.81 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34q.118.04.243.054c.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.33.33 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501"/>
</svg></i> Select your country</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://kasookoo.com/">Nigeria</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://kasookoo.com/">Ghana</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://kasookoo.com/">Kenya</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://standardpro.mtn.ci/">Cote d’ivoire</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://kasookoo.com/">South Africa</a></li>
                            <li><a class="dropdown-item" target="_blank" href="https://standardpro.mtn.bj/">Benin</a></li>
                          </ul>
                        </li>
                      </ul>
              <ul class="navbar-nav justify-content-end flex-grow-1 w-lg-50 mx-auto">
                <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Products
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <ul class="mega-col">
                      <div class="mega-col-title  big">CUSTOMER ENGAGEMENT</div>
                      <li>
                        <a class="dropdown-item d-flex" href="/#/cloud-numbering-service">
                          <span class="mega-item-icon">
                            <img
                              src={connect}
                              alt="Cloud Numbering Service"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Cloud Numbering Service
                            </span>
                            <span class="mega-item-text">
                              Elevate your telecommunications experience
                            </span>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item d-flex" href="/#/ai-solutions">
                          <span class="mega-item-icon">
                            <img
                              src={aiheadphones}
                              alt="AI Solutions"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              AI Solutions
                            </span>
                            <span class="mega-item-text">
                              AI solutions focused on African languages
                            </span>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item d-flex" href="/#/contact-center">
                          <span class="mega-item-icon">
                            <img src={headphones} alt="Contact Centre" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Contact Centre</span>
                            <span class="mega-item-text">
                              Create a personalized customer experience
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/campaign-manager"
                        >
                          <span class="mega-item-icon">
                            <img src={send} alt="Campaign Manager" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Campaign Manager
                            </span>
                            <span class="mega-item-text">
                              Craft, customize, and initiate large-scale
                              messaging campaigns
                            </span>
                          </span>
                        </a>
                      </li>
                      <div class="mega-col-title  big">BUSINESS TELEPHONY</div>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/kasookoo-for-crm"
                        >
                          <span class="mega-item-icon">
                            <img src={call} alt="Toku for Microsoft Teams " />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Kasookoo for CRM
                            </span>
                            <span class="mega-item-text">
                              Embrace a deskphone-free environment
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/zoho-phonebridge-integration"
                        >
                          <span class="mega-item-icon">
                            <img src={integration} class="integration-logo" alt="Kasookoo for CRM " />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Integrations
                            </span>
                            <span class="mega-item-text">
                            Incorporate Calling Seamlessly into Your Work Processes
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item d-flex" href="/#/kasookoo-for-zoom">
                          <span class="mega-item-icon">
                            <img
                              src={connect}
                              alt="Kasookoo for Zoom Phone"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Kasookoo for Zoom Phone
                            </span>
                            <span class="mega-item-text">
                              All-in-one business communications platform
                            </span>
                          </span>
                        </a>
                      </li>
                      
                    </ul>
                    <ul class="mega-col">
                      <div class="mega-col-title  big">
                        EMBEDDED COMMUNICATION
                      </div>
                      {/* <div class="mega-col-title ">VOICE</div> */}

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/programmable-voice"
                        >
                          <span class="mega-item-icon">
                            <img src={record} alt="Programmable Voice" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Programmable Voice
                            </span>
                            <span class="mega-item-text">
                              Integrate voice call functionality into your
                              product
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/in-app-voice-calling"
                        >
                          <span class="mega-item-icon">
                            <img src={speaker} alt="In-App Voice Calling" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              In-App Voice Calling
                            </span>
                            <span class="mega-item-text">
                              Empower your app with the capability of voice
                              calling
                            </span>
                          </span>
                        </a>
                      </li>
                      {/* <div class="mega-col-title ">MESSAGING</div> */}

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/programmable-messaging"
                        >
                          <span class="mega-item-icon">
                            <img src={email} alt="Programmable Messaging" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Programmable Messaging
                            </span>
                            <span class="mega-item-text">
                              Experience the messaging journey you aspire
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Solutions
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <ul class="mega-col">
                      <div class="mega-col-title  big">BY INDUSTRY</div>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/government-solution"
                        >
                          <span class="mega-item-icon">
                            <img
                              src={government_icon}
                              alt="Government & Political Campaign/Surveys"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Government & Political Campaign/Surveys
                            </span>
                            <span class="mega-item-text">
                              Interact with a large audience of citizens and
                              staff members
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item d-flex" href="/#/FinTech">
                          <span class="mega-item-icon">
                            <img src={fintech_icon} alt="Fintech" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Fintech</span>
                            <span class="mega-item-text">
                              Manage your finance, banking and investment with a
                              smooth digital experience
                            </span>
                          </span>
                        </a>
                      </li>

                      {/* <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/sharing-economy"
                        >
                          <span class="mega-item-icon">
                            <img
                              src={sharing_icon}
                              alt="Sharing Economy "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Sharing Economy
                            </span>
                            <span class="mega-item-text">
                              Boost your operational efficiency and
                              communications
                            </span>
                          </span>
                        </a>
                      </li> */}

                      {/* <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/insurance-services"
                        >
                          <span class="mega-item-icon">
                            <img
                              src={insurace_icon}
                              alt="Insurance Services "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Insurance Services
                            </span>
                            <span class="mega-item-text">
                              Create a better experience for your agents and
                              customers
                            </span>
                          </span>
                        </a>
                      </li> */}

                      <li>
                        <a class="dropdown-item d-flex" href="/#/EduTech">
                          <span class="mega-item-icon">
                            <img src={edutech_icon} alt="Edutech" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Edutech</span>
                            <span class="mega-item-text">
                              Empowering minds through education technology
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/outbound-callcentre"
                        >
                          <span class="mega-item-icon">
                            <img
                              src={call_center_icon}
                              alt="Outbound Call Centre"
                              style={{height:"30px"}}
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Outbound Call Centre
                            </span>
                            <span class="mega-item-text">
                              Connecting customers, delivering solutions
                            </span>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item d-flex" href="/#/debtcollection">
                          <span class="mega-item-icon">
                            <img
                              src={debt_collector_icon}
                              alt="Debt Collection"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Debt Collection</span>
                            <span class="mega-item-text">
                              Reclaiming Financial Freedom, One Step at a Time
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item d-flex" href="/#/hostpitality">
                          <span class="mega-item-icon">
                            <img
                              src={travel_icon}
                              alt="Travel &amp; Hospitality "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Travel &amp; Hospitality
                            </span>
                            <span class="mega-item-text">
                              Build an omnichannel hospitality experience
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/retail-ecommerce"
                        >
                          <span class="mega-item-icon">
                            <img
                              src={retial_icon}
                              alt="E-commerce &amp; FMCG "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              E-commerce &amp; FMCG
                            </span>
                            <span class="mega-item-text">
                              Design great purchasing experiences with
                              convenience
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                    <ul class="mega-col">
                      <div class="mega-col-title  big">BY USE CASE</div>

                      <li>
                        <a class="dropdown-item d-flex" href="/#/number-masking">
                          <span class="mega-item-icon">
                            <img src={number_icon} alt="Number Masking " />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Number Masking </span>
                            <span class="mega-item-text">
                              Ensure user privacy through anonymization
                              techniques
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/#/user-varification"
                        >
                          <span class="mega-item-icon">
                            <img
                              src={user_verification_icon}
                              alt="User Verification "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              User Verification
                            </span>
                            <span class="mega-item-text">
                              Authenticate legitimate users across multiple
                              channels
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/#/consutling">
                    Consulting
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a class="nav-link" href="/#/why-yuave">
                    Why Yuave
                  </a>
                </li> */}
                <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Resources
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <ul class="mega-col">
                      <li>
                        <a class="dropdown-item d-flex" href="/#/customer">
                          <span class="mega-item-icon">
                            <img
                              src={customer_stories_icon}
                              alt="Customer Stories"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Customer Stories
                            </span>
                            <span class="mega-item-text"></span>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="https://kasookoo.com/downloads/kasookoo-app.zip"
                        >
                          <span class="mega-item-icon">
                            <img src={resDesktoApp} alt="Customer Stories" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Kasookoo Desktop App
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item d-flex" href="/#/faqs">
                          <span class="mega-item-icon">
                            <img src={resFaq} alt="FAQs" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">FAQs</span>
                            <span class="mega-item-text"></span>
                          </span>
                        </a>
                      </li>

                      {/* <li>
                                <a
                                  class="dropdown-item d-flex"
                                  
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src={resourcesForDevs}
                                      alt="forDevs"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                     For Developer
                                    </span>
                                    <span class="mega-item-text">
                                     
                                    </span>
                                  </span>
                                </a>
                              </li> */}
                      <li>
                        <a class="dropdown-item d-flex" href="/#/gdpr">
                          <span class="mega-item-icon">
                            <img src={resDataProtection} alt="DataProtection" />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              EU Data Protections
                            </span>
                            <span class="mega-item-text"></span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/#/about-us">
                    About Us
                  </a>
                </li>
                {/* <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Support
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <ul class="mega-col">
                      

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/help-center"
                        >
                          <span class="mega-item-icon">
                            <img
                              src={help_center_icon}
                              alt="Help Centre   "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Help Centre </span>
                            <span class="mega-item-text">
                              Need help? Get support, read our FAQs, and find
                              info on our products
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li> */}

                {/* <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="/#/careers"
                    // data-bs-toggle="dropdown"
                  >
                    Careers
                  </a>
                </li> */}
              </ul>
              <div class="offcanvas-body-footer text-center my-3">
                <a
                  href="/#/contact-us"
                  class="btn btn-big btn-yellow border-radius-50 px-5 mx-auto ms-md-0 "
                  target=""
                >
                  Contact us
                </a>
              </div>
              {/* <div class="offcanvas-body-footer text-center my-3">
                <a
                  href="/login"
                  class="btn btn-big btn-yellow border-radius-50 px-5 mx-auto ms-md-0 navbar-login-button"
                  target=""
                >
                  Login
                </a>
              </div> */}
            </div>
          </div>
        </div>

        {props.first_section && (
          <div
            id="hero"
            class="container-fluid text-white position-relative num-hero-1 hero-contact_center pb-5 num-hero-1 hero-right_image_in_archer"
            // style={{paddingLeft:'0px',paddingRight:'0px',position:'relative'}}
          >
            <div class="container pb-md-5 mb-md-5">
              <style></style>
              <div class="row align-items-center pb-md-5 mb-2 mb-md-4">
                <div class="col-12 col-lg-6">
                  <div class="w-100 text-center text-md-start">
                    <div class="d-block mb-3">
                      <h1 class="style-h5">{props.extra_heading}</h1>
                    </div>
                    <div class="d-block mb-4">
                      <h2 class="style-h1">{props.heading}</h2>
                    </div>
                    <div class="d-block mb-4">
                      <div class="p-big">
                        <p>{props.text}</p>
                      </div>
                    </div>
                    {!props.isnotVisible &&
                    <div class="d-block text-center text-md-start">
                      <button
                        class="btn btn-big btn-yellow border-radius-50 px-5 mx-auto ms-md-0"
                        onClick={() => {
                          props.setIsFormOpen(!props.isFormOpen);
                        }}
                       >
                        Talk to an Expert
                       </button>
                      </div>
}
                     </div>
                  
                </div>
                
                {!props.imgnotVisible && <div class="col-12 col-lg-6 text-center">
                  <img
                    class="mb-md-2 mb-lg-0 mx-auto mx-md-0 mw-100 my-5 my-sm-0 img-hero"
                    src={props.img}
                    alt="Frame 46308"
                  />
                </div>}
              </div>
            </div>
            <div>
              <svg
                width="1922"
                className="w-100"
                height="95"
                viewBox="0 0 1922 95"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1921 106.499L1 108.499C359.524 34.3011 572.442 10.9574 962.5 0.999023C1423.21 5.83129 1581.73 27.7713 1921 106.499Z"
                  stroke="#FFFFFF"
                  stroke-width="10"
                  fill="#FFFFFF"
                ></path>
              </svg>
            </div>
          </div>
        )}
      </main>

      {/* <div className="text-white" id='first-section' style={{zIndex:'100'}}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-sm-6 welcome-text-div h-100">
                            <div className="row h-100">
                                <div className="col-11  h-100 d-flex flex-column justify-content-center pl-3">
                                  <p className="xm-font bold">{props.extra_heading}</p>
                                    <p id='main-text' className="xxxl-font bold">{props.heading}</p>
                                    <p className='xxsm-font mt-3 semi-bold'>{props.text}</p>
                                    <span><button className='talk-btn mt-3 bold'>Talk to an Expert</button></span>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 welcome-img-div">
                            <div className='d-flex h-100 justify-content-center align-items-center position-relative'>
                                <img className='' src={props.img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
        
                <ContactForm/>
        
    </div> */}

      {/* <div id="hero" class="container-fluid text-white position-relative num-hero-1 hero-contact_center pb-5 ">
        
        <div class="container pb-md-5 mb-md-5">
       
<div class="row align-items-center pb-md-5 mb-2 mb-md-4">
    <div class="col-12 col-lg-6">
        <div class="w-100 text-center text-md-start">
            <div class="d-block mb-3">
                <h1 class="style-h5">{props.extra_heading}</h1>
            </div>
            <div class="d-block mb-4">
                <h2 class="style-h1">{props.heading}</h2>
            </div>
            <div class="d-block mb-4">
                <div class="p-big"><p>{props.text}</p>
</div>
            </div>
                            <div class="d-block text-center text-md-start">
                    <a href="#Solutions" class="btn btn-big btn-yellow border-radius-50 px-5 mx-auto ms-md-0" target="" data-bs-toggle="modal" data-bs-target="#Solutions">Talk to a Toku Expert</a>
                </div>
                    </div>
    </div>
    <div class="col-12 col-lg-6 text-center">
                    <img class="mb-md-2 mb-lg-0 mx-auto mx-md-0 mw-75 my-5 my-sm-0" src={props.img}/>
            </div>
</div>
</div></div> */}

      {props.isFormOpen && (
        <ContactForm
          ContactCenter={props.contact_center_form}
          setIsFormOpen={props.setIsFormOpen}
        />
      )}
    </div>
  );
};

export default Navbar;
